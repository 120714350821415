import { Button } from '@econsult/econsult-ui/dist/components/Button';
import StyledButton from '@econsult/econsult-ui/dist/components/Button/ButtonStyles';
import { InputElement, InputWrapper } from '@econsult/econsult-ui/dist/components/Input/InputStyles';
import { FieldSet, Form } from 'components/Form';
import { InputRow } from 'components/ScheduleClosureForm/style';
import { Divider } from 'components/Typography';
import styled from 'styled-components';

export const EmisCredentialsForm = styled(Form)`
  ${InputWrapper} {
    width: 100%;
  }

  ${InputElement} {
    place-items: initial;
  }

  && input::-webkit-inner-spin-button,
  && input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  && input[type="number"] {
    -moz-appearance: textfield;
  }

  ${StyledButton} {
    margin-top: calc(21.6px + 0.25rem);
    min-height: 33px;
  }

  ${FieldSet} {
    > * + * {
      margin-top: 1.5rem;
    }
  }

  ${InputRow} {
    gap: 1rem;
    align-items: flex-start;
  }

  ${InputWrapper} {
    position: relative;
  }

  ${InputElement} {
    border-radius: 8px;
    padding: 12px 10px 12px 16px;
    height: 33px;
    min-height: unset;
  }

  ${InputElement} + div[data-lastpass-icon-root] {
    grid-row: 2;
    grid-column: 1;
  }

  & + ${Divider} {
    margin-block: 0.35rem 1.15rem;
  }
`;

export const DeleteButton = styled(Button).attrs({
  size: 'small',
  iconPosition: 'left',
  fillStyle: 'borderless',
})`
  &${StyledButton} {
    color: ${({ theme }) => theme.colorStatusError};
    
    && {
      margin-left: 1rem;
    }
  }

  svg {
    margin-bottom: 3px;
  }
`;
