import { STRINGS } from 'config';

const {
  RESET_ALL_STATE,
  SET_PRACTICE,
  SET_USER_DATA,
} = STRINGS.REDUCERS.APP;

export const initialAppState = {
  selectedPractice: null,
  selectedPracticeIsEhub: null,
  selectedPracticeHasAdminRights: null,
  user: {
    practices: [],
    ehubs: [],
    adminRights: [],
    currentUserEmail: null,
  },
};

const checkForInitialAdminRights = payload => {
  const userIsAdminOfFirstListedPractice = payload?.adminRights?.includes(payload?.practices[0]?.id);
  const eHubOnlyUserIsAdminOfFirstListedEhub = (!payload?.practices[0] && payload?.adminRights?.includes(payload?.ehubs[0]?.id));
  return userIsAdminOfFirstListedPractice || eHubOnlyUserIsAdminOfFirstListedEhub;
};

export const appReducer = (state, action) => {
  switch (action.type) {
    case RESET_ALL_STATE: {
      return { ...initialAppState, selectedPractice: state.selectedPractice };
    }
    case SET_PRACTICE: {
      return {
        ...state,
        selectedPractice: action.payload.practice,
        selectedPracticeIsEhub: action.payload.isEhub,
        selectedPracticeHasAdminRights: state.user.adminRights.includes(action.payload.practice.id),
      };
    }
    case SET_USER_DATA: {
      const userHasOnlyEhubsAndNoPractices = !!(!action.payload?.practices[0] && action.payload?.ehubs);
      return {
        ...state,
        selectedPractice: action.payload.selectedPractice,
        selectedPracticeIsEhub: userHasOnlyEhubsAndNoPractices,
        selectedPracticeHasAdminRights: checkForInitialAdminRights(action.payload),
        user: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
