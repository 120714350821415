const subdomain = `testing-${process.env.REACT_APP_INPUT_ENV}`;
const primaryCareDomain = `${process.env.REACT_APP_INPUT_ENV}.testing.econsulttest.health`;

export const dteConfig = {
  API: {
    ROOT_URL: `https://api.${subdomain}.econsult.awshealth.net`,
    CUSTOMISATION_URL: `https://api.${subdomain}.econsult.awshealth.net/customisation`,
    PRACTICE_URL: `https://api.${subdomain}.econsult.awshealth.net/public/practice`,
  },
  PRIMARY_CARE_DOMAIN: primaryCareDomain,
  COOKIES: {
    DOMAIN: 'awshealth.net',
  },
  INTERCOM: {
    ENABLED: true,
    APP_ID: 'wmmrp56d', // eConsult Health [TEST]
  },
};
